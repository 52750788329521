"use client";

import type { SwipeDirection } from "@radix-ui/react-toast";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "~/components/ui/toast";
import { useToast } from "~/components/ui/use-toast";

interface ToasterProps {
  position?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
}

const directionsMap: { [key: string]: SwipeDirection } = {
  "top-right": "right",
  "top-left": "left",
  "bottom-right": "right",
  "bottom-left": "left",
};

export const Toaster: React.FC<ToasterProps> = ({ position }) => {
  const { toasts } = useToast();

  const swipeDirections = position ? directionsMap[position] : "right";

  return (
    <ToastProvider swipeDirection={swipeDirections}>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} position={position} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport position={position} />
    </ToastProvider>
  );
};
