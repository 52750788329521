"use client";

import type { PropsWithChildren } from "react";
import { SWRConfig } from "swr";

import { apiService } from "~/services/apiService";

const fetcher = (url: string) => apiService.get(url);

export default function SWRProvider({ children }: PropsWithChildren) {
  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>;
}
