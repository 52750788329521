"use client";

import { useEffect } from "react";
import { setCookie } from "cookies-next";

import { camelToSnakeCase, getCookieOptions } from "~/shared/utils/utils";

const setUtmToCookies = (search: string) => {
  const params = new URLSearchParams(search);
  const options = getCookieOptions();
  const keys = ["utm_", "plan", "appSumoCode"];

  params.forEach((value, key) => {
    if (key === "callbackUrl") {
      setUtmToCookies(value.slice(value.indexOf("?")));
    } else if (keys.some((k) => key.startsWith(k))) {
      setCookie(camelToSnakeCase(key), value, options);
    }
  });
};

export const UTMParams = () => {
  useEffect(() => {
    setUtmToCookies(window.location.search);
  }, []);

  return null;
};
