"use client";

import { useCallback, useMemo } from "react";

import * as TourPrimitive from "@reactour/tour";

import { ChevronLeft, ChevronRight, Close as CloseIcon } from "~/assets/icons";
import { useLockBodyScroll } from "~/shared/hooks/use-lock-body-scroll";

type ClickProps = Pick<
  TourPrimitive.TourProps,
  "setIsOpen" | "setCurrentStep" | "currentStep" | "setSteps" | "meta" | "setMeta"
> & { steps?: TourPrimitive.StepType[] };

type StylesFnProps = { [key: string]: string | number };

const Arrow: React.FC<{ inverted?: boolean; disabled?: boolean }> = ({ inverted, disabled, ...props }) => {
  const Comp = inverted ? ChevronRight : ChevronLeft;

  return <Comp className={disabled ? "opacity-50" : ""} {...props} />;
};

const Close: React.FC<{ onClick?: () => void }> = ({ ...props }) => {
  return <CloseIcon className="ml-auto cursor-pointer h-4 w-4" {...props} />;
};

export const TourProvider: React.FC<TourPrimitive.ProviderProps> = (props) => {
  const { enableScroll, disableScroll } = useLockBodyScroll();

  const resetOnClose = useCallback(({ setCurrentStep, setIsOpen }: ClickProps) => {
    setCurrentStep(0);
    setIsOpen(false);
  }, []);

  const handleKeyboard = useCallback(
    (e: KeyboardEvent, clickProps?: ClickProps) => {
      if (clickProps === undefined) return;

      const { setCurrentStep, steps, currentStep } = clickProps;

      if (e.key === "ArrowRight") {
        setCurrentStep(Math.min(currentStep + 1, (steps?.length ?? 1) - 1));
      }
      if (e.key === "ArrowLeft") {
        setCurrentStep(Math.max(currentStep - 1, 0));
      }
      if (e.key === "Escape") {
        resetOnClose(clickProps);
      }
    },
    [resetOnClose]
  );

  const styles = useMemo(
    () => ({
      popover: (base: StylesFnProps) => ({
        ...base,
        backgroundColor: "rgb(var(--primary-100))",
        borderRadius: "calc(var(--border-radius) * 4)",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
        padding: "1rem",
        fontSize: "var(--fs-sm)",
        color: "rgb(var(--grey-700))",
      }),
      maskArea: (base: StylesFnProps) => ({ ...base, rx: 15 }),
      dot: (base: StylesFnProps, state?: StylesFnProps) => ({
        ...base,
        backgroundColor: state?.current ? "rgb(var(--secondary-600))" : "rgb(var(--primary-100))",
      }),
    }),
    []
  );

  return (
    <TourPrimitive.TourProvider
      padding={{ popover: 20 }}
      styles={styles}
      afterOpen={disableScroll}
      beforeClose={enableScroll}
      onClickMask={resetOnClose}
      onClickClose={resetOnClose}
      keyboardHandler={handleKeyboard}
      components={{ Arrow, Close }}
      showBadge={false}
      disableDotsNavigation
      disableInteraction
      scrollSmooth
      {...props}
    />
  );
};
