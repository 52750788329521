"use client";

import { useEffect } from "react";
import { useSession } from "next-auth/react";

import Intercom from "@intercom/messenger-js-sdk";

import { INTERCOM_APP_ID } from "~/services/__constants";

export const IntercomInitializer: React.FC = () => {
  const { data: session } = useSession();

  useEffect(() => {
    const intercomSettings = {
      app_id: INTERCOM_APP_ID as string,
      ...(session
        ? {
            email: session.user.email as string,
            user_id: session.id as string,
            name: session.user.name as string,
          }
        : {}),
    };

    Intercom(intercomSettings);
  }, [session]);

  return null;
};
