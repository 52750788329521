import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import * as ToastPrimitives from "@radix-ui/react-toast";

import { Close } from "~/assets/icons";
import { cn } from "~/shared/utils/utils";

const ToastProvider = ToastPrimitives.Provider;

const toastPositions = cva("fixed z-[100] flex gap-2 max-h-screen max-w-[420px] w-full flex-col-reverse p-4", {
  variants: {
    position: {
      "top-left": "top-0 left-1/2 -translate-x-1/2 desktop:left-0 desktop:translate-x-0",
      "top-right": "top-0 right-1/2 translate-x-1/2 desktop:right-0 desktop:translate-x-0",
      "bottom-left": "bottom-0 left-1/2 -translate-x-1/2 desktop:left-0 desktop:translate-x-0",
      "bottom-right": "bottom-0 right-1/2 translate-x-1/2 desktop:right-0 desktop:translate-x-0",
    },
  },
  defaultVariants: {
    position: "top-right",
  },
});

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport> & VariantProps<typeof toastPositions>
>(({ className, position, ...props }, ref) => (
  <ToastPrimitives.Viewport ref={ref} className={cn(toastPositions({ position }), className)} {...props} />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  "group pointer-events-auto relative text-white flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80",
  {
    variants: {
      variant: {
        success: "border-success-100 bg-success-100 text-success-600",
        error: "border-danger-100 bg-danger-100 text-danger-700",
      },
      position: {
        "top-left":
          "data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-top-full data-[state=open]:desktop:slide-in-from-left-full data-[state=open]:desktop:slide-in-from-top-0",
        "top-right":
          "data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:desktop:slide-in-from-right-full data-[state=open]:desktop:slide-in-from-top-0",
        "bottom-left":
          "data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-bottom-full data-[state=open]:desktop:slide-in-from-left-full data-[state=open]:desktop:slide-in-from-bottom-0",
        "bottom-right":
          "data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-bottom-full data-[state=open]:desktop:slide-in-from-right-full data-[state=open]:desktop:slide-in-from-bottom-0",
      },
    },
    defaultVariants: {
      variant: "success",
      position: "top-right",
    },
  }
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastVariants>
>(({ className, variant, position, ...props }, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastVariants({ variant, position }), className)} {...props} />;
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      "inline-flex h-8 shrink-0 items-center justify-center rounded-2 border bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      className
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "absolute right-2 top-2 rounded-1 p-1 opacity-0 transition-opacity focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100",
      className
    )}
    toast-close=""
    {...props}
  >
    <Close className="h-4 w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title ref={ref} className={cn("text-sm font-semibold", className)} {...props} />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description ref={ref} className={cn("text-sm opacity-90", className)} {...props} />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  Toast,
  ToastAction,
  type ToastActionElement,
  ToastClose,
  ToastDescription,
  type ToastProps,
  ToastProvider,
  ToastTitle,
  ToastViewport,
};
