import { useCallback, useState } from "react";

export function useLockBodyScroll() {
  const [originalStyle, setOriginalStyle] = useState<string>("");

  const enableScroll = useCallback(() => {
    document.body.style.overflow = originalStyle;
  }, [originalStyle]);

  const disableScroll = useCallback(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    setOriginalStyle(originalStyle);
    document.body.style.overflow = "hidden";
  }, []);

  return { enableScroll, disableScroll };
}
